<template>
  <el-dialog
    v-if="modal"
    :visible="modal"
    width="500px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    @close="onModalChange"
  >
    <div class="main-pane">
      <GroupVehicleTree
        ref="treeRef"
        class="TreeOuter"
        :defaultExpandAll="true"
        :showCheckbox="showCheckbox"
        :showSearch="true"
        showVehicle
        :updateVehicleTime="15"
        :visibleFilter="onTreeFilter"
        :defaultCheckedKeys="checkData"
        @node-check="onNodeClick"
      >
        <template v-slot="{ data }">
          <div class="NodeLabel" :title="getNodeLabel(data)">
            {{ getNodeLabel(data) }}
          </div>
        </template>
      </GroupVehicleTree>
    </div>
    <template #footer>
      <div class="footer">
        <el-button @click="onModalChange(false)">取 消</el-button>

        <el-button @click="sumbitClick" type="primary">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import GroupVehicleTree from "@/components/VehicleGroupTree";

export default {
  name: "BindVehicle",
  model: {
    prop: "modal",
    event: "modal-change"
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },

    showCheckbox: {
      // 是否有复选框
      type: Boolean,
      default: false
    },

    checkData: {
      // 已经绑定的车辆数据
      type: Array,
      default: []
    },

    isFilter: {
      // 是否进行显示过滤
      type: Boolean,
      default: true
    },
  },
  components: {
    GroupVehicleTree
  },
  data() {
    return {
      selectData: [] // 选择的车辆数据
    };
  },

  methods: {
    // 关闭弹窗
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.$emit("modal-change", !!modal);
      this.$emit(eventName, ...options);
    },

    // 树节点点击事件
    onNodeClick(data, checked) {
      // 判断勾选状态
      if (checked) {
        return this.selectData = this.$refs.treeRef.getCurrentNode();
      }

      // 删除取消勾选的数据
      this.selectData = this.selectData.filter(item => item.groupId !== data.groupId || item.V !== data.V);
    },

    // 筛选节点
    onTreeFilter(data) {
      if (data.nodeType === 2 && this.isFilter) {
        return [0, 2, 3].includes(data.Z);
      }
      return true;
    },
    getNodeLabel(data) {
      // 车组
      if (data.nodeType === 1) return data.groupName;
      // 车辆
      if (data.nodeType === 2) return data.P;
      // 通道号
      if (data.nodeType === 2) return data.label;
    },

    // 确定点击事件
    async sumbitClick() {
      const vehicles = this.selectData.filter((item) => item.nodeType === 2);
      const groups = this.selectData.filter((item) => item.nodeType !== 2);

      this.$emit("on-bind", { vehicles, groups });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-pane {
  height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.TreeOuter {
  flex: 1;
  overflow: hidden;
}
</style>

