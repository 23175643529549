import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl);

/**
 * 获取用户下区域规则
 * @param {JSON} val
 */
export const getAllArea = (val) => { return axios.get('saftyZone/getAllArea.do', { params: val }); };


/**
 * 区域绑定车辆
 * @param {JSON} val
 */
export const bindVehiclePath = (val) => { return axios.get('VehBindPath/bindVehiclePath.do', { params: val }); };

/**
 * 通过规则ID获取规则绑定车辆
 * @param {JSON} val
 */
export const getVehicleInfoByPathId = (val) => { return axios.get('pathRule/getPathRuleBindVeh', { params: val }); };

/**
 * 获取单个规则信息
 * @param {JSON} val
 */
export const getPointInfo = (val) => { return axios.get('saftyZone/getPointInfo.do', { params: val }); };

/**
 * 删除规则信息
 * @param {JSON} val
 */
export const delPathByPathId = (val) => { return axios.get('saftyZone/delPathByPathId.do', { params: val }); };


/**
 * 更新规则
 * @param {FormData} formData
 * @param {JSON} config
 */
export const updatePath = (formData, config) => { return axios.post('saftyZone/updatePath.do', formData, config); };

/**
 * 添加规则
 * @param {FormData} formData
 * @param {JSON} config
 */
export const addSaftyPath = (formData, config) => { return axios.post('saftyZone/addSaftyPath.do', formData, config); };


/**
 * 电子围栏-查询区域
 * @param {JSON} val
 */
export const getTreeArea = (val) => { return axios.get('saftyZone/getTreeArea', { params: val }); };

/**
 * 电子围栏-修改区域分类
 * @param {JSON} val
 */
export const addRegion = (val) => {
    return axios.post('saftyZone/addRegion', val, {
        headers: { "Content-Type": "application/json" }
    });
};

/**
 * 电子围栏-修改区域分类
 * @param {JSON} val
 */
export const editRegion = (val) => {
    return axios.post('saftyZone/updateSaftyPath', val, {
        headers: { "Content-Type": "application/json" }
    });
};

/** 修改区域分类 */
export const deleteRegion = (val) => { return axios.get('saftyZone/delSaftyPathByPathId', { params: val }); };


/**
 * 查询区域-明细
 * @param {JSON} val
 */
export const getPointMsg = (val) => { return axios.get('saftyZone/getPointMsg', { params: val }); };

/**
 * 查询区域内车辆
 * @param {JSON} val
 */
export const getCustomAreaVehicle = (val) => { return axios.get('areaVehicleCtrl/getCustomAreaVehicle', { params: val }); };

/**
 * 查询省市内车辆
 * @param {JSON} val
 */
export const getRegionCodeVehicle = (val) => { return axios.get('areaVehicleCtrl/getRegionCodeVehicle', { params: val }); };

/**
 * 分组监控-根据车辆id查询最后位置信息
 * @param {JSON} val
 */
export const getVehicleLastPosInfo = (val) => { return axios.get('areaVehicleCtrl/getVehicleLastPosInfo', { params: val }); };


/**
 * 查询区域规则list
 */
export const getAreaRules = (val) => { return axios.get('pathRule/getAllPathRule', { params: val }); };

/**
 * 新增区域规则
 * @param {JSON} val
 */
export const addPathRule = (val) => {
    return axios.post('pathRule/addPathRule', val, {
        headers: { "Content-Type": "application/json" }
    });
};

/**
 * 修改区域规则
 * @param {JSON} val
 */
export const updatePathRule = (val) => {
    return axios.post('pathRule/updatePathRule', val, {
        headers: { "Content-Type": "application/json" }
    });
};

/**
 * 删除区域规则
 */
export const deletePathRule = (val) => {
    return axios.get('pathRule/deletePathRule', { params: val });
};

/** 根据区域id查区域规则 */
export const getPathRuleInfobyId = (val) => {
    return axios.get('pathRule/getPathRuleInfo', { params: val });
};

/** 查询车辆回传 */
export const searchPostBac = (val) => {
    return axios.get('postBack/searchPostBack', { params: val });
};