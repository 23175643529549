<template>
  <div class="comp_outer">
    <div class="row">
      <div class="title">通道ID</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.channel"
          size="mini"
          controls-position="right"
          :min="1"
          :max="16"
          :step="1"
        ></el-input-number>
      </div>
    </div>
    <div class="row">
      <div class="title">拍摄命令</div>
      <div class="content">
        <el-select v-model="valueClone.shootCmd" size="mini">
          <el-option
            v-for="item in shootCmdOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- <div class="row">
      <div class="title">拍摄张数</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.photoNum"
          controls-position="right"
          size="mini"
          :step="1"
          :min="1"
          :max="65535"
        ></el-input-number>
      </div>
    </div>-->
    <!-- <div class="row">
      <div class="title">拍照间隔/录像时间</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.photoTime"
          controls-position="right"
          size="mini"
          :step="1"
          :min="1"
          :max="65535"
        ></el-input-number>
      </div>
    </div>-->
    <div class="row">
      <div class="title">保存标志</div>
      <div class="content">
        <el-select v-model="valueClone.saveFlag" size="mini">
          <el-option
            v-for="item in saveFlagOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="title">分辨率</div>
      <div class="content">
        <el-select v-model="valueClone.dpi" size="mini">
          <el-option
            v-for="item in dpiOptions"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="row">
      <div class="title">图片/视频 质量(1 ~ 10)</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.quality"
          controls-position="right"
          size="mini"
          :step="1"
          :min="1"
          :max="10"
        ></el-input-number>
      </div>
    </div>

    <div class="row">
      <div class="title">亮度(0 ~ 255)</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.luminance"
          controls-position="right"
          size="mini"
          :step="1"
          :min="0"
          :max="255"
        ></el-input-number>
      </div>
    </div>

    <div class="row">
      <div class="title">对比度(0 ~ 127)</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.contrast"
          controls-position="right"
          size="mini"
          :step="1"
          :min="0"
          :max="127"
        ></el-input-number>
      </div>
    </div>

    <div class="row">
      <div class="title">饱和度(0 ~ 127)</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.saturability"
          controls-position="right"
          size="mini"
          :step="1"
          :min="0"
          :max="127"
        ></el-input-number>
      </div>
    </div>

    <div class="row">
      <div class="title">色度(0 ~ 255)</div>
      <div class="content">
        <el-input-number
          v-model="valueClone.chroma"
          controls-position="right"
          size="mini"
          :step="1"
          :min="0"
          :max="255"
        ></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 拍照参数
 */

// { 
//type:拍照类型：默认为1，1--照片.; 
// cameraline:摄像头路数; 
// disRate:辨析率；--1-8( // 1--320*240 // 2--640*480 // 3--800*600 // 4--1024*768 // 5--176*144 // 6--352*288 // 7--704*288 // 8--704*576); 
// uploadFlag:上传标志 -0保存 /1立即上传; 
// pictureQuality：图片质量--1到10;
//  brightness：亮度 --0到255; 
// contrastRatio:对比度--0到127; 
// saturation:饱和度--0到127; 
// chroma:色度--0到255;
//  }

const params = {
  channel: 1, //通道ID
  shootCmd: 1, //拍摄命令
  photoNum: 1, //拍照张数 //拍照有效
  photoTime: '', //拍照间隔/录像时间
  saveFlag: 0, //保存标志
  dpi: 1, //分辨率
  quality: '', //图片/视频 质量
  luminance: '',  //亮度
  contrast: '',  //对比度
  saturability: '',  //饱和度
  chroma: '', //色度
  remark: '', //备注
  type: 1, //拍照类型
};

//拍摄命令
const shootCmdOptions = [
  // { value: 0, label: '停止录像' },
  // { value: 65535, label: '录像' },
  { value: 1, label: '拍照' },
];

//保存标志
const saveFlagOptions = [
  { value: 1, label: '保存' },
  { value: 0, label: '实时上传' },
];

//分辨率
const dpiOptions = [
  { value: 0, label: '最低分辨率' },
  { value: 1, label: '320 × 240' },
  { value: 2, label: '640 × 480' },
  { value: 3, label: '800 × 600' },
  { value: 4, label: '1024 × 768' },
  { value: 5, label: '176 × 144' },
  { value: 6, label: '352 × 288' },
  { value: 7, label: '704 × 288' },
  { value: 8, label: '704 × 576' },
  { value: 255, label: '最高分辨率' },
];

export default {
  name: 'PhotoParamCard',
  components: {

  },
  props: {
    value: {
      type: Object,
      default() {
        return params;
      }
    }
  },
  data() {
    return {
      shootCmdOptions,
      saveFlagOptions,
      dpiOptions,
    };
  },
  computed: {
    valueClone: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  beforeMount() {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.comp_outer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.row {
  display: flex;
  // flex-direction: column;
  margin: 5px 0;
  width: 50%;
}
.title {
  width: 39%;
  text-align: right;
  margin: 0 15px 0 0;
}
.content {
  width: 50%;
}
.el-input-number {
  width: 182px;
}
</style>
